import { MathUtils } from 'three';
import style from './Home.module.css';
import { Trans, useTranslation } from 'react-i18next';
import { Fragment } from 'react';

const members = [
  { img: 'godtail', name: 'GODTAIL', role: 'GODTAIL CREATOR & ART DIRECTOR' },
  { img: 'yarieos', name: 'Yarieos', role: 'DEVELOPER' },
  { img: 'thatonekidlum', name: 'thatonekidlum', role: 'PRODUCER' },
  { img: 'fujita', name: 'Fujita', role: 'ART TEAM' },
  { img: 'macci', name: 'MAcci', role: 'ART TEAM' },
  { img: 'hiwana', name: 'Hiwana', role: 'ART TEAM' },
  { img: 'baowolf', name: 'BaoWolf', role: 'ADVISOR' },
  { img: 'james', name: 'James', role: 'DEVELOPER' },
  { img: 'phil', name: 'pbillingsby', role: 'DEVELOPER' },
  { img: 'mohammed', name: 'Mohammed Alsabaawi', role: 'DEVELOPER' },
  { img: 'ashieee', name: 'Ashieee', role: 'STRATEGIC ADVISORY' },
  { img: 'image0', name: 'Bawsa', role: 'MARKETING SPECIALIST' },
  { img: 'ven', name: 'Ven', role: 'COMMUNICATIONS' },
  { img: 'jon', name: 'Jon_HQ', role: 'DISCORD SPECIALIST' },
  { img: 'toya', name: 'Toya', role: 'MODERATOR' },
  { img: 'pnizo', name: 'pnizo', role: 'MODERATOR' },
  { img: 'lycan', name: 'Lycan', role: 'MODERATOR' },
  { img: 'nadir', name: 'Nadir', role: 'COMMUNITY MANAGER' },
  { img: 'ben', name: 'Ben', role: 'MARKETING ADVISOR' },
  { img: 'bear', name: 'Bear', role: 'INTERN' },
];

export function Home() {
  /*
   * properties
   */

  const { t } = useTranslation('translation');

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <div className={style.home}>
        {/* <About /> */}

        <img id="section1" src="/images/logo.webp" className={style.imgFull} />

        <hr />

        <img src="/images/hero.webp" className={style.imgFull} />

        <hr />

        <div id="section2" className={style.section}>
          <h2>{t('about.title')}</h2>
          <p>
            <Trans i18nKey="about.body" />
          </p>
        </div>

        <hr />

        <div className={style.imgsAbout}>
          <img src="/images/female/CatGirl.webp" className={style.imgFull} />
          <img src="/images/female/Eve.webp" className={style.imgFull} />
          <img src="/images/female/GreenGirl.webp" className={style.imgFull} />
          <img src="/images/female/PurpleGirl.webp" className={style.imgFull} />
        </div>

        <hr />

        <div id="section3" className={style.section}>
          <h2>{t('collection.title')}</h2>
          <h3>{t('collection.subtitle1')}</h3>
          <div className={style.imgsAbout}>
            <img src={`/images/promos/${MathUtils.randInt(1, 8)}.webp`} className={style.imgFull} />
            <img src={`/images/promos/${MathUtils.randInt(9, 16)}.webp`} className={style.imgFull} />
            <img src={`/images/promos/${MathUtils.randInt(15, 22)}.webp`} className={style.imgFull} />
            <img src={`/images/promos/${MathUtils.randInt(23, 30)}.webp`} className={style.imgFull} />
          </div>
          <h3>{t('collection.subtitle2')}</h3>
          <p>
            <Trans i18nKey="collection.body" />
          </p>
        </div>

        <hr />

        <div id="section4" className={style.section}>
          <h2>{t('timeline.title')}</h2>
          <p>
            <Trans i18nKey="timeline.body" />
          </p>
        </div>

        <hr />

        <div id="section5" className={style.section}>
          <div className={style.soon}>
            <img src="/images/soon.webp" className={style.imgSoon} />
            <div>
              <h2>{t('next.title')}</h2>
              <p>
                <Trans i18nKey="next.body" />
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div id="section6" className={style.section}>
          <h2>{t('team.title')}</h2>
          <p>
            <Trans i18nKey="team.body" />
          </p>
          <div className={style.team}>
            {members.map((data, index) => (
              <Fragment key={index}>
                <Member index={index} data={data} />
              </Fragment>
            ))}
          </div>
        </div>

        <hr />
      </div>
    </>
  );
}

function Member({ data }) {
  return (
    <div className={style.member}>
      <img src={`/images/team/${data.img}.webp`} />
      <h4>{data.name}</h4>
      <p>{data.role}</p>
    </div>
  );
}
