import { useEffect, useState } from 'react';
import { StandardButton } from '../buttons/StandardButton';
// import { Connection } from '@solana/web3.js';
// import { useWallet } from '@solana/wallet-adapter-react';
// import { mintNFT } from './mintNFT';
import PriorityFeeSlider from './PriorityFeeSlider';
import style from './MintModal.module.css';
import { useTranslation } from 'react-i18next';

export function MintModal({ setShowMintModal, mintAmount, setMintAmount }) {
  const { t } = useTranslation('translation', { keyPrefix: 'mintModal' });

  const baseCost = 1; // Base cost per mint in SOL
  const [mintCost, setMintCost] = useState(baseCost);
  const [priorityFee, setPriorityFee] = useState(100000);
  const [isMinting, setIsMinting] = useState(false);

  // Wallet Adapter hook
  // const { connected, publicKey, connect } = useWallet();

  // Create Solana connection
  // const connection = new Connection('https://api.devnet.solana.com', 'confirmed');

  useEffect(() => {
    setMintCost((mintAmount > 0 ? mintAmount : 1) * baseCost);
  }, [mintAmount]);

  const handleMint = async () => {
    if (isMinting) return; // Prevent multiple mint actions
    setIsMinting(true);
    // Mint logic
  };

  return (
    <div className={style.modalOverlay}>
      <div className={style.modalBackdrop} onClick={() => setShowMintModal(false)} />
      <div className={style.modalContent}>
        <h1 className={style.title}>{t('header')}</h1>
        <input
          type="number"
          min="1"
          max="2"
          value={mintAmount || 1}
          className={style.amountInput}
          onChange={(e) => setMintAmount(Math.max(1, Math.min(10, parseInt(e.target.value, 10) || 1)))}
        />
        <PriorityFeeSlider
          priorityFee={priorityFee || 100000}
          setPriorityFee={setPriorityFee}
          numNFTs={mintAmount || 1}
        />
        <div className={style.modalButtons}>
          <StandardButton onClick={() => setShowMintModal(false)} disabled={isMinting}>{t('cancel')}</StandardButton>
          <StandardButton onClick={handleMint} disabled={isMinting}>{isMinting ? t('minting') : t('mint')}</StandardButton>
        </div>
        <p>{t('cost')} {mintCost > 0 ? mintCost : baseCost} SOL</p>
      </div>
    </div>
  );
}
