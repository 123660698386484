import React from 'react';
// import './PriorityFeeSlider.module.css';
import style from './PriorityFeeSlider.module.css';
import { useTranslation } from 'react-i18next';

function PriorityFeeSlider({ priorityFee, setPriorityFee, numNFTs }) {
  const { t } = useTranslation('translation', { keyPrefix: 'mintModal' });

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setPriorityFee(newValue);
  };

  // Calculate total priority fee
  const totalPriorityFee = priorityFee * numNFTs;

  return (
    <div className={style.container}>
      <p className={style.body}>{t('body', { total: totalPriorityFee.toLocaleString(), nft: numNFTs })}</p>
      <input
        type="range"
        min="5000"
        max="500000"
        step="5000"
        value={priorityFee}
        onChange={handleChange}
        className={style.slider}
      />
    </div>
  );
}

export default PriorityFeeSlider;
